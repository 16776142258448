import { memo } from "react";
import GeneralButton from "../buttons/GeneralButton";

import { RxCross1 } from "react-icons/rx";
import { BiErrorCircle } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { loginFailure } from "../../store/auth/action";

function AuthError() {
  const dispatch = useDispatch();

  const { error } = useSelector((state) => ({ error: state.error }));

  return (
    <div className="z-[100] fixed w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 bottom-0 left-0 right-0 flex items-center justify-center">
      <div className="bg-[#ffffff] shadow h-max w-max flex flex-col gap-4">
        
          <div className="flex flex-row items-center pr-8 pt-8 pl-8 w-full justify-between">
            <div className="Roboto flex flex-row items-center gap-2 text-zinc-600 text-base font-bold leading-normal tracking-wide">
              <BiErrorCircle className="flex items-center" />
              <div className="Roboto flex items-center gap-2 text-zinc-600 text-base font-bold leading-normal tracking-wide">Error!</div>
            </div>

            <div>
              <RxCross1
                className="cursor-pointer"
                onClick={() => dispatch(loginFailure(""))}
              />
            </div>
          </div>
        <div className="px-8 pb-8 flex flex-col w-max gap-4">
          <div className="px-[24px] Roboto w-max">
            <p className="Roboto flex items-center w-[344px] text-zinc-600 text-[13px] font-normal leading-none tracking-wide">{error}</p>
          </div>

          <div className="w-full flex justify-center">
            <GeneralButton
              text={"Okay"}
              onclick={() => dispatch(loginFailure(""))}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(AuthError);
