
import axios from "axios";
const API_ENDPOINT = require("../config/server");
const USER_LOG_API = require("../config/userlog.server");
// session start for dashbaord

export async function userLogsAPI(
  session_id,
  type,
  value,
  userDetails,
  clientname,
  pagename,
  clickType,
  clientId,
  dashboardId,
  startTime,
  endTime
) {

  try{

  
  const payload = {
    Client: clientname,
    SessionId: session_id,
    JobTitle: userDetails?.jobTitle,
    UserName: userDetails?.email,
    Department: userDetails?.department,
    PageName: pagename,
    Type: type,
    Value: value,
    clickType:clickType,
    clientId:clientId,
    dashboardId:dashboardId,
    startTime:startTime,
    endTime:endTime
  };
  const axiosoptions = {
    url: `${USER_LOG_API}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: `${session_id}`,
    },
    data: payload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}catch(err){
  console.log(err)
}
}



export async function startDashboardSession(token, session_id, dashboard) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "START_DASHBOARD_SESSION",
      Method: "POST",
    },

    Parameters: {
      dashboardname: dashboard,
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `${token.trim()}`,
      session_id: `${session_id}`,
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function authenticateDashboardSession(session_id, token) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "AUTHENTICATE_DASHBOARD_SESSION",
      Method: "POST",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: `${session_id}`,
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function accessEmbeddedDashboard(
  session_id,
  token,
  client_id,
  shortcut
) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "ACCESS_EMBEDED_DASHBOARD",
      Method: "POST",
    },
    Parameters: {
      clientId: client_id,
      embeded: shortcut,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `${token}`,
      session_id: `${session_id}`,
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}
