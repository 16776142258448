import { memo, useEffect } from "react";

import { getSession } from "../utils/sessionstore";
import {
  addDashboardFeedback,
  addOrgs,
  addTimerPopUp,
  showTour,
} from "../store/auth/action";
import {
  refreshRequest,
  refreshSuccess,
  refreshFailure,
  listBanner,
  listFavourites,
  listNotification,
  newslistSuccess,
  listWhitePapers,
  updateFeedbackStatus,
  listSubscriptions,
  listTrendings,
  listUserDetails,
} from "../store/auth/action";
import { refreshService } from "../services/refresh.service";
import { getAllClients } from "../services/hwai_user.service";

import { useLocation, useNavigate ,useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const { userDetails } = useSelector((state) => ({
    userDetails: state.userDetails,
  }));
  const { clientID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const session_id = getSession("session_hwai");
  const hwai_user_token = getSession("session_hwai_user");
  const client_id = getSession("client_id");

  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const clientid = getSession("client_id");


  const userD = useSelector((state) => state.userDetails);

  async function onRefresh(session_id, hwai_user_token, clientid) {
    try {
      const payload = { token: session_id, clientid: clientid };

      dispatch(refreshRequest(payload));
      var orglist = [];
      if (hwai_user_token) {
        const response = await getAllClients(hwai_user_token);
        // console.log("I am reloading", response); 
        orglist = response?.clients;
        dispatch(addOrgs(response?.clients));
      }
      const response = await refreshService(payload);
      const userData = response?.data?.data?.user;

      const subsData = response?.data?.data?.subscriptions;
      const trendingsData = response?.data?.data?.trendings;
      const timerPopupData = response?.data?.data?.timerpopup;
      const dashboardFeedbackData = response?.data?.data?.dashboardfeedback;

      const sessionData = response?.data?.session;
      var bannerData = {};
      if (userData?.bannerId) {
        bannerData = userData?.bannerId;
      }

      const favData = response?.data?.data?.favourites?.slice(0, 12);
      const notData = response?.data?.data?.notification;
      const newsData = response?.data?.data?.newsinfo;

      const whData = userData?.whitepaperIds;
      const tempToken = sessionData?.userId;
      const feedbackstatus = response?.data?.data?.feedback;

      const tempuserDetails = {
        userSQLId: userData?.userSQLId,
        trendingConfig: userData?.trendingConfig,
        userName: userData?.userName,
        email: userData?.email,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        address: userData?.address,
        mobile: userData?.mobile,
        about: userData?.about,
        permissions: userData?.permissions,
        jobTitle: userData?.jobTitle,
        department: userData?.department,
        userId:userData?._id,
        clientId:userData?.clientId?._id
      };
      const logo = userData?.clientId.logo;
      const clientId = userData?.clientId;
      // console.log("jai mata di", timerPopupData);
      // dispatch(showTour(userData?.showTour));
      dispatch(addDashboardFeedback(dashboardFeedbackData));
      dispatch(
        refreshSuccess(sessionData, logo, clientId, orglist, tempuserDetails)
      );
      dispatch(listNotification(notData));
      dispatch(listBanner(bannerData));
      dispatch(listSubscriptions(subsData));
      dispatch(listFavourites(favData));
      dispatch(listWhitePapers(whData));
      dispatch(listTrendings(trendingsData));
      dispatch(listUserDetails(tempuserDetails));
      dispatch(newslistSuccess(newsData));
      dispatch(updateFeedbackStatus(feedbackstatus));
    } catch (err) {
      console.log(err.message);
    }
  }

  const subscriptionActive = useSelector((state) => state.subscriptionActive);
  const subscriptionMessage = useSelector((state) => state.subscriptionMessage);

  useEffect(() => {
    if (!session_id) {
      navigate("/login");
    } else {
      if (userDetails?.permissions === "HWAI_ADMIN" && clientID !== undefined) {
        onRefresh(session_id, hwai_user_token, clientID);
      } else {
        onRefresh(session_id, hwai_user_token, client_id);
      }
    }
  }, [session_id, hwai_user_token]);

  useEffect(() => {
    if (subscriptionActive == false) {
      if (pathParts[1]) {
        if (userD.permissions != "HWAI_ADMIN") {
          navigate("/");
        }
      }
    }
  }, [subscriptionActive]);

  return children;
};

export default memo(PrivateRoute);
