import { Routes, Route } from "react-router-dom";

import NotFound from "../views/NotFound";
import { Suspense, lazy, useState, useEffect, memo } from "react";

import StartLoader from "../components/loaders/StartLoader";
import LogTimerPop from "../components/popups/LogTimerPop";
import PrivateRoute from "./PrivateRoute";
import ResetPassword from "../components/forms/ResetPassword";
import DefaultLayout from "./DefaultRouteLayout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginLayout from "./LoginRouteLayout";

import UnsubscribedPop from "../components/popups/UnsubscribedPop";

import {
  logoutRequest,
  logoutSuccess,
  logoutFailure,
} from "../store/auth/action";
import EmbeddedLinkDashboard from "../views/EmbeddedLinkDashboard";
import { useSelector } from "react-redux";
import AdminRoute from "./AdminRoute";

const Home = lazy(() => import("../views/Home"));
const Dashboard = lazy(() => import("../views/Dashboard"));
const ProfileLayout = lazy(() => import("../layouts/ProfileLayout"));
const Login = lazy(() => import("../views/Login"));
const TicketLayout = lazy(() => import("../layouts/TicketLayout"));
const WhitePaperLayout = lazy(() => import("../layouts/WhitePaperLayout"));

const HwaiLogin = lazy(() => import("../views/HwaiLogin"));

const Router = () => {
  const [remainingTime, setRemainingTime] = useState(30 * 60 * 1000); // 30 minutes
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openTimerPop, setOpenTimerPop] = useState(false);
  const [onclosed, setOnclosed] = useState(false);
  const [timerAuth, setTimerAuth] = useState(true);

  function logOut() {
    try {
      setTimerAuth(false);

      setOnclosed(true);
      setOpenTimerPop(false);
      dispatch(logoutRequest());

      dispatch(logoutSuccess());
      navigate("/login");
    } catch (err) {
      dispatch(logoutFailure(err.message));
    }
  }

  function continueSession() {
    navigate("/");
  }
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("session_hwai"));
    // console.log("Running in useEfffect");
    if (data) {
      let intervalId = setInterval(() => {
        const currentTime = new Date().getTime();

        const timeDifference =
          new Date(data.expiration).getTime() - currentTime;

        const seconds = Math.floor(timeDifference / 1000);
        // Extract hours, minutes, and seconds
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        // Format the time string
        const timeString = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;

        // console.log(timeString, "current time");

        if (Number(timeDifference) <= 0) {
          // session token has expired
          logOut();
          clearInterval(intervalId);
          setRemainingTime(0);
        }
        if (
          Number(remainingSeconds) > 0 &&
          Number(remainingSeconds) <= 30 &&
          Number(minutes) <= 0 &&
          Number(hours) <= 0 &&
          timerAuth === true
        ) {
          clearInterval(intervalId);
          setRemainingTime(timeString);
          setOpenTimerPop(true);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      setRemainingTime(null);
    }
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/:clientID?"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <Home />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />
  {/* Type here refers to the section from which the user has clicked. */}
        <Route
          path="/:clientID?/dashboard/:id/:type?"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <Dashboard />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />
{/* Type here refers to the section from which the user has clicked. */}
        <Route
          path="/redirect/:dashboard/:type?"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <EmbeddedLinkDashboard />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />

        <Route
          path="/:clientID?/subscribe"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <UnsubscribedPop />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />

        <Route
          path="/:clientID?/ticket"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <TicketLayout />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            <LoginLayout>
              <Suspense fallback={<StartLoader />}>
                <Login />
              </Suspense>
            </LoginLayout>
          }
        />
        <Route
          path="/auth/forgotpassword/:session_id"
          element={<ResetPassword />}
        />

        <Route
          path="/:clientID?/profile"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <ProfileLayout />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />

        <Route
          path="/:clientID?/whitepapers"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <WhitePaperLayout />
                </DefaultLayout>
              </Suspense>{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/hwai-login"
          element={
            <Suspense fallback={<StartLoader />}>
              <AdminRoute>
                <HwaiLogin />
              </AdminRoute>
            </Suspense>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {openTimerPop && (
        <LogTimerPop
          onLogout={logOut}
          remainingTime={remainingTime}
          onContinue={continueSession}
          onClose={() => {
            setOnclosed(true);
            setOpenTimerPop(false);
          }}
        />
      )}
    </>
  );
};

export default memo(Router);
