import { memo } from "react";

const GeneralButton = ({ text, onclick, isDisabled }) => {
  return (
    <button
      className=" disabled:bg-[] bg-[#7D528B] hover:bg-[#4A1E57] px-4 py-1 border justify-center items-center gap-1 cursor-pointer"
      onClick={onclick}
      disabled={isDisabled}
    >
      <div className="text-[#ffffff] text-[13px] leading-tight tracking-wide">{text}</div>
    </button>
  );
};

export default memo(GeneralButton);
