import { memo, useEffect, useRef, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";

const GeneralSearchDropDown = ({ checked, options, setChecked, h = 10 }) => {
  const [displayChecked, setDisplayChecked] = useState("-Select-");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (isDropdownOpen) {
      inputRef.current?.focus();
    }
  }, [isDropdownOpen]);

  const handleOptionClick = (option) => {
    setChecked(option);
    setDisplayChecked(option.name || option.clientname);
  };

  const filteredOptions = options.filter((option) => {
    const name = option.name || option.clientname;
    return name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleDropdownToggle = (e) => {
    e.stopPropagation(); 
    setDropdownOpen(!isDropdownOpen);
    if (!isDropdownOpen) {
      setSearchQuery("");
    }
  };

  const isOptionSelected = (option) => {
    const optionName = option.name || option.clientname;
    return (
      displayChecked === optionName ||
      (checked && (checked.name || checked.clientname) === optionName)
    );
  };

  return (
    <div ref={dropdownRef} className="relative inline-block text-left w-full">
      <div>
        <button
          type="button"
          onClick={handleDropdownToggle}
          className={`inline-flex justify-between items-center w-full h-${h} border border-[#E5E7EB] px-2 py-1 text-sm leading-6 Roboto tracking-[0.7px] outline-none`}
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          {checked?.name || checked?.clientname || displayChecked}
          <div className="flex justify-center items-center w-6 h-6">
            <AiOutlineDown size={14} />
          </div>
        </button>
      </div>
      {isDropdownOpen && (
        <div className="origin-top absolute right-0 mt-1 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-30">

          <div className="pt-1 px-1">
            <input
              ref={inputRef}
              type="text"
              className="w-full p-1 border-b border-gray-200 text-sm leading-6 Roboto tracking-[0.7px] outline-none"
              placeholder="Search..."
              value={searchQuery}
              onClick={(e) => e.stopPropagation()} 
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="max-h-[200px] overflow-y-auto p-1">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  onClick={() => {
                    handleOptionClick(option);
                    setDropdownOpen(false);
                  }}
                  className={`flex p-1 cursor-pointer flex-row hover:bg-[#E9E8E8] ${
                    isOptionSelected(option) ? "bg-[#DDDDDC]" : ""
                  }`}
                  role="menuitem"
                >
                  <label className="text-sm leading-6 text-[#333333] Roboto tracking-[0.7px] flex items-center cursor-pointer">
                    {option.name || option.clientname}
                  </label>
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">No options found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(GeneralSearchDropDown);
