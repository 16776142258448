import axios from "axios";
const dotenv=require("dotenv")
require("dotenv").config();
const API_ENDPOINT = require("../config/server");
const passphrase = `${process.env.REACT_APP_ENCRYPTION_KEY}`;

const CryptoJS = require("crypto-js");
// fake commit
export async function authenticate(payload) {
  const encryptedString = CryptoJS.AES.encrypt(
    payload.password.trim(),
    passphrase
  ).toString();

  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "LOGIN_USER",
      Method: "POST",
    },

    Parameters: {
      data: { email: payload.email.trim(), password: encryptedString },
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function forgotUserPassword(email) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "FORGOT_PASSWORD",
      Method: "POST",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      user: email,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}

export async function resetPassword(session_id, payload) {
  const encryptedString = CryptoJS.AES.encrypt(
    payload.password.trim(),
    passphrase
  ).toString();

  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "RESET_USER_PASSWORD",
      Method: "PATCH",
    },

    Parameters: {
      data: { password: encryptedString },
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: session_id,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}

export async function authenticateTableau() {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "AUTHENTICATE_TABLEAU",
      Method: "GET",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: formattedPayload,
  };
  const response = await axios(axiosoptions);

  return response.data;
}

export async function getTableauTicket(targetSite,accesstype) {
  try {
    // console.log(accesstype, "I am the access type")
    let formattedPayload = {
      Dashboard_Details: {
        Dashboard_Name: "Lobby",
        Dashboard_Version: "V1",
        API_Route: "GET_TABLEAU_TICKET",
        Method: "GET",
      },

      Parameters: {
        target_site: targetSite,
         accesstype:accesstype,
        data: {},
      },
    };

    const axiosoptions = {
      url: `${API_ENDPOINT}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: formattedPayload,
    };
    const response = await axios(axiosoptions);
    // console.log(response.data, "reponse.data from service");

    return response.data;
  } catch (err) {
    console.log("ERROR", err);
  }
}

export async function verifySession(session_id) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "VERIFY_SESSION",
      Method: "POST",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: session_id,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}
