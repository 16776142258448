import { createStore } from "redux";

import { authReducer } from "./auth/reducer";

// const rootReducer=combineReducers({
//     auth:authReducer,
//     app:todosReducer
// })

export const store = createStore(
  authReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
