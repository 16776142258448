import axios from "axios";

const API_ENDPOINT = require("../config/server");

export async function sendMailToSales(payload) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "CONTACT_SALE_MAIL",
      Method: "POST",
    },
    Parameters: {
      data: payload,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",

    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}
