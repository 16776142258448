const ExpiredSession = () => {
  return (
    <div className="w-full h-screen absolute top-0 right-0 bottom-0 left-0">
      <div className="m-auto w-1/2 text-7xl font-bold mt-36 Roboto">
        Oops! Link has expired
      </div>
    </div>
  );
};

export default ExpiredSession;
