import { memo, useEffect } from "react";

import { getSession } from "../utils/sessionstore";

import { useNavigate } from "react-router-dom";

const AdminRoute = ({ children }) => {
 const navigate=useNavigate()

  useEffect(() => {
    const userDetails = getSession("userDetails");
    // console.log(userDetails,"userDetails from admin route protector")
   
    if(userDetails?.permissions!=="HWAI_ADMIN"){
      navigate("/")
      return
    }
      
  }, []);

  return children;
};

export default memo(AdminRoute);
