import axios from "axios";
import { setSession } from "../utils/sessionstore";

const API_ENDPOINT = require("../config/server");

export async function searchDashboardService(token, searchTerm, clientId) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "SEARCH_DASHBOARD",
      Method: "GET",
    },

    Parameters: {
      clientId: clientId,
      search: searchTerm,
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token.trim()}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  const now = new Date();
  // set the expiry time as 1hr s after the current time
  const expiresAt = new Date(+now + 2 * 60 * 60 * 1000);
  setSession("isAuth", true, expiresAt);
  setSession("session_hwai", token.trim(), expiresAt);

  return response.data;
}
