import { memo, useEffect, useRef, useState } from "react";

import AuthError from "../errors/AuthError";
import SearchBar from "../bars/SearchBar";
import { Link, useParams } from "react-router-dom";
import {
  hideTour,
  setActiveTourComponent,
  setTourActivity,
  showDashboardFeedback,
  showHelpDoc,
  showTour,
} from "../../store/auth/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import OrgsDropDown from "../dropdowns/OrgsDropDown";
import { MdHelpOutline } from "react-icons/md";

import { RiCustomerService2Line } from "react-icons/ri";
import { PiUserCircleLight } from "react-icons/pi";
import TopForkTourCard from "../tours/TopForkTourCard";
import { getHelpDocPopUpById } from "../../services/helpDocPopup.service";
import { getSession } from "../../utils/sessionstore";
import { fabClasses } from "@mui/material";
import HelpGuide from "./HelpGuide";

const Header = () => {
  const { clientID } = useParams();

  const dispatch = useDispatch();

  const {
    isTourActive,
    activeTourComponent,
    activedashboard,
    userDetails,
    error,
    orgs,
  } = useSelector((state) => ({
    activedashboard: state.activedashboard,
    userDetails: state.userDetails,
    error: state.error,
    orgs: state.orgs,
    isTourActive: state.isTourActive,
    activeTourComponent: state.activeTourComponent,
  }));
  const [helpActive, setHelpActive] = useState(false);
  //
  const [notificationActive, setNotificationActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location.pathname.split("/");

  function onNextToTicket() {
    dispatch(hideTour());
    dispatch(setTourActivity(false));
    dispatch(setActiveTourComponent({}));
    // call api
  }

  const [helpObj, setHelpObj] = useState([]);
  const [open, setOpen] = useState(false);

  async function getHelpDocPopUpByIdAPI(id) {
    try {
      const token = getSession("session_hwai");
      const response = await getHelpDocPopUpById(token, id);
      // console.log(response);
      setHelpObj(response.data || []);
      setOpen(true);
      return response?.data;
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    // Close the context menu when a click occurs outside
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setHelpActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuRef = useRef(null);
  //again check 14
  return (
    <>
      <div className="absolute z-10 h-14 bg-headerbg pt-4 left-[45px] top-0 right-0 items-center flex justify-center border-b border-[#DDDDDC]">
        <HelpGuide
          open={open}
          setOpen={setOpen}
          data={helpObj}
          setData={setHelpObj}
        />
        <div className="justify-between flex absolute left-[45px] right-[45px] top-0 items-center mt-1  mx-auto">
          {/* <img
            alt="HWAI"
            src={logo}
            className="h-8  cursor-pointer"
            onClick={() => navigate("/")}
          /> */}
          <div className="flex gap-3 justify-start items-center">
            <div
              onClick={() => {
                if (
                  userDetails?.permissions === "HWAI_ADMIN" &&
                  clientID !== undefined
                ) {
                  navigate(`/${clientID}`);
                } else {
                  navigate("/");
                }
              }}
              className="cursor-pointer w-[186px]"
            >
              <img
                alt="HWAI"
                src={
                  "https://lobbystore.blob.core.windows.net/images/HWAI_Logo_Full.svg?v=1"
                }
                className="cursor-pointer select-none pointer-events-none"
              />
            </div>
            {orgs?.length > 0 && userDetails?.permissions === "HWAI_ADMIN" && (
              <div className="">
                <OrgsDropDown />
              </div>
            )}
          </div>

          <div className="flex gap-4 justify-end items-center">
            <div className="mb-2">
              <SearchBar
                notificationActive={notificationActive}
                setNotificationActive={setNotificationActive}
              />
            </div>

            <div className="flex flex-row items-center gap-3">
              {pathParts.includes("dashboard") && (
                <div
                  className="cursor-pointer flex items-center pt-1"
                  onClick={() => dispatch(showDashboardFeedback())}
                >
                  <img
                    src="https://lobbystore.blob.core.windows.net/images/feedback-icon.svg?v=1"
                    alt="feedback"
                  />
                </div>
              )}
              <div className="relative flex items-center">
                {isTourActive && activeTourComponent?.name === "Ticket" && (
                  <div className="z-10 absolute top-0 left-0 -translate-x-[251px] translate-y-[38px]">
                    <TopForkTourCard
                      // onNext={onNextToTicket}
                      number={6}
                      description={
                        "Need assistance or want to report an issue? Raise a ticket easily to get help from our support team. Your concerns are our top priority."
                      }
                      title={"Raise ticket"}
                      icon={
                        "https://lobbystore.blob.core.windows.net/tour/ri_customer-service-2-line.svg"
                      }
                    />
                  </div>
                )}
                <button
                  className="cursor-pointer flex items-center"
                  onClick={() => {
                    if (
                      clientID !== undefined &&
                      orgs.map((org) => org._id).includes(clientID) &&
                      userDetails?.permissions === "HWAI_ADMIN"
                    ) {
                      navigate(`/${clientID}/ticket`);
                    } else {
                      navigate("/ticket");
                    }
                  }}
                >
                  <RiCustomerService2Line
                    className="fill-[#5C5C5C] hover:fill-[#333333]"
                    size={24}
                  />
                </button>
              </div>
              {/* help option start */}
              <div
                onClick={() => {
                  setHelpActive(!helpActive);
                  // console.log(pathParts);
                }}
                className="cursor-pointer flex items-center pt-[2px]"
              >
                <div className="relative">
                  <MdHelpOutline
                    className="fill-[#5C5C5C] hover:fill-[#333333]"
                    size={24}
                  />
                  {helpActive && (
                    <div className="absolute top-0 right-0 border-none translate-y-[30px] w-max h-max">
                      <div
                        ref={menuRef}
                        className="w-max h-max p-1 bg-[#ffffff] border border-[#494949] shadow-md flex flex-col"
                      >
                        <button
                          onClick={() => {
                            // dispatch(
                            //   setActiveTourComponent({ name: "Nav", number: 1 })
                            // );
                            // dispatch(setTourActivity(true));
                            // dispatch(hideTour());
                            dispatch(showTour(true));
                            if (pathParts[1] && pathParts[1] !== "") {
                              if (
                                userDetails?.permissions === "HWAI_ADMIN" &&
                                clientID !== undefined
                              ) {
                                navigate(`/${clientID}`);
                              } else {
                                navigate("/");
                              }
                            }
                          }}
                          className={`flex flex-row gap-1 px-1 py-[1px]  items-center justify-end ${
                            !(
                              pathParts[1] == "" ||
                              (pathParts.length == 2 &&
                                pathParts[1] == clientID)
                            )
                              ? "opacity-50"
                              : "hover:bg-[#d3d3d3]"
                          }`}
                          disabled={
                            !(
                              pathParts[1] == "" ||
                              (pathParts.length == 2 &&
                                pathParts[1] == clientID)
                            )
                          }
                          // className="flex flex-row gap-1 px-1 py-[1px]  hover:bg-[#d3d3d3] items-center justify-start"
                        >
                          <img
                            alt="tour"
                            src="https://lobbystore.blob.core.windows.net/images/take-tour-icon.svg?v=1"
                          />
                          <div className="text-sm text-[#333333] Roboto text-left">
                            Take a tour
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            if (activedashboard?.helpDoc.includes("popupId-")) {
                              const popupId =
                                activedashboard?.helpDoc.split("-")[1];
                              getHelpDocPopUpByIdAPI(popupId);
                            } else {
                              dispatch(showHelpDoc());
                            }
                          }}
                          className={`flex flex-row gap-1 px-1 py-[1px] items-center justify-end ${
                            !(
                              pathParts.includes("dashboard") &&
                              activedashboard?.helpDoc &&
                              activedashboard?.helpDoc?.length > 0
                            )
                              ? "opacity-50"
                              : "hover:bg-[#d3d3d3]"
                          }`}
                          disabled={
                            !(
                              pathParts.includes("dashboard") &&
                              activedashboard?.helpDoc &&
                              activedashboard?.helpDoc?.length > 0
                            )
                          }
                        >
                          <img
                            alt="tour"
                            src="https://lobbystore.blob.core.windows.net/images/help-guide.svg?v=1"
                          />
                          <div className="text-sm text-[#333333] Roboto text-left">
                            Help Guide
                          </div>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* help option ends */}
              <div
                onClick={() => {
                  if (
                    clientID !== undefined &&
                    orgs.map((org) => org._id).includes(clientID) &&
                    userDetails?.permissions === "HWAI_ADMIN"
                  ) {
                    navigate(`/${clientID}/profile`);
                  } else {
                    navigate("/profile");
                  }
                }}
              >
                <div className="Roboto gap-2 flex text-[14px] cursor-pointer border rounded-[100px] p-[1px] pr-1 border-[#DDDDDC]">
                  <PiUserCircleLight className=" text-2xl" />
                  {userDetails?.firstName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {error && error.length > 0 && <AuthError />}
    </>
  );
};

export default memo(Header);
