const NotFound = () => {
  return (
    <div className="w-full h-screen absolute top-0 right-0 bottom-0 left-0  flex justify-center items-center bg-white">
      <div className="text-center flex flex-col h-max gap-4">
        <h1 className="text-[100px] text-[#5C276E] font-bold h-max">404</h1>
        <div className="h-[4px] w-[4px]"></div>
        <p className="text-[16px] font-normal font-Roboto text-[#5C5C5C] h-max">Oops! The page you're looking for doesn't exist.</p>
        {/* <button className="bg-white text-blue-500 px-6 py-3 rounded-lg shadow-lg hover:shadow-xl hover:bg-blue-400 transition duration-300">
          Go Back
        </button> */}
      </div>
    </div>
  );
};

export default NotFound;
