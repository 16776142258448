const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";
const HIDE_SALESPOP = "HIDE_SALESPOP";
const LOGOUT_REQUEST = "LOGOUT_REQUEST";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const LOGOUT_FAILURE = "LOGOUT_FAILURE";
const SHOW_TOUR = "SHOW_TOUR";
const HIDE_TOUR = "HIDE_TOUR";
const SWITCH_MASK = "SWITCH_MASK";
const ADD_DRAG_ITEM = "ADD_DRAG_ITEM";
const REFRESH_REQUEST = "REFRESH_REQUEST";
const REFRESH_SUCCESS = "REFRESH_SUCCESS";
const REFRESH_FAILURE = "REFRESH_FAILURE";
const SHOW_HELPDOC = "SHOW_HELPDOC";
const HIDE_HELPDOC = "HIDE_HELPDOC";
const STOP_LOAD = "STOP_LOAD";
const SHOW_SALESPOP = "SHOW_SALESPOP";
const INVOKE_LOAD = "INVOKE_LOAD";
const ADD_SINGLE_FAVOURITE = "ADD_SINGLE_FAVOURITE";
const TRENDING_LIST = "TRENDING_LIST";
const SUBSCRIPTION_LIST = "SUBSCRIPTION_LIST";
const USERDETAILS_LIST = "USERDETAILS_LIST";
const NOTIFICATIONS_LIST = "NOTIFICATIONS_LIST";
const FAVOURITE_LIST = "FAVOURITE_LIST";
const WHITEPAPER_LIST = "WHITEPAPER_LIST";
const BANNER_LIST = "BANNER_LIST";
const SHOW_TRENDINGDOC = "SHOW_TRENDINGDOC";
const HIDE_TRENDINGDOC = "HIDE_TRENDINGDOC";
const SHOW_FEEDBACK_FORM = "SHOW_FEEDBACK_FORM";
const HIDE_FEEDBACK_FORM = "HIDE_FEEDBACK_FORM";
const UPDATE_CLIENTNAME = "UPDATE_CLIENTNAME";
const NEWS_LIST_REQUEST = "NEWS_LIST_REQUEST";
const NEWS_LIST_SUCCESS = "NEWS_LIST_SUCCESS";
const NEWS_LIST_FAILURE = "NEWS_LIST_FAILURE";

const HIDE_TRENDINGFORM = "HIDE_TRENDINGFORM";
const SHOW_TRENDINGFORM = "SHOW_TRENDINGFORM";
const ADD_TIMERPOPUP = "ADD_TIMERPOPUP";
const CLIENT_LIST_REQUEST = "CLIENT_LIST_REQUEST";
const CLIENT_LIST_SUCCESS = "CLIENT_LIST_SUCCESS";
const CLIENT_LIST_FAILURE = "CLIENT_LIST_FAILURE";
const ADD_SINGLE_AVAILABLETOFAVOURITE = "ADD_SINGLE_AVAILABLETOFAVOURITE";
const REQUEST_PROFILE_UPDATE = "REQUEST_PROFILE_UPDATE";
const SUCCESS_PROFILE_UPDATE = "SUCCESS_PROFILE_UPDATE";
const FAILURE_PROFILE_UPDATE = "FAILURE_PROFILE_UPDATE";
const SHOW_DASHBOARD_FEEDBACK = "SHOW_DASHBOARD_FEEDBACK";
const HIDE_DASHBOARD_FEEDBACK = "HIDE_DASHBOARD_FEEDBACK";
const ACCEPT_SUCCESS = "ACCEPT_SUCCESS";
const INVOKE_SUCCESS = "INVOKE_SUCCESS";
const INVOKE_ACTION = "INVOKE_ACTION";

const INVOKE_ERROR = "INVOKE_ERROR";
const ACCEPT_ERROR = "ACCEPT_ERROR";
const REMOVE_SINGLE_AVAILABLETOFAVOURITE = "REMOVE_SINGLE_AVAILABLETOFAVOURITE";
const REMOVE_FAVOURITE_REQUEST = "REMOVE_FAVOURITE_REQUEST";
const REMOVE_FAVOURITE_SUCCESS = "REMOVE_FAVOURITE_SUCCESS";
const REMOVE_FAVOURITE_FAIURE = "REMOVE_FAVOURITE_FAIURE";
const SHOW_BANNER_FORM = "SHOW_BANNER_FORM";
const HIDE_BANNER_FORM = "HIDE_BANNER_FORM";
const ADD_FAVOURITE_REQUEST = "ADD_FAVOURITE_REQUEST";
const ADD_FAVOURITE_SUCCESS = "ADD_FAVOURITE_SUCCESS";
const ADD_FAVOURITE_FAIURE = "ADD_FAVOURITE_FAIURE";
const HWAIUSER_LOGIN_SUCCESS = "HWAIUSER_LOGIN_SUCCESS";
const UPDATE_FEEDBACK_STATUS = "UPDATE_FEEDBACK_STATUS";

const SET_ACTIVE_DASHBOARD = "SET_ACTIVE_DASHBOARD";
const ADD_ORGS = "ADD_ORGS";
const ADD_LOG_ORG = "ADD_LOG_ORG";
const UPDATE_SESSION = "UPDATE_SESSION";
const LIST_AVAILABLE_TO_FAVOURITE = "LIST_AVAILABLE_TO_FAVOURITE";
const SELECT_NEWTABOBJECT = "SELECT_NEWTABOBJECT";
const SELECT_RIGHT_CLICK_OPTION_POSITION = "SELECT_RIGHT_CLICK_OPTION_POSITION";
const DESELECT_NEWTABOBJECT = "DESELECT_NEWTABOBJECT";
const ADD_DASHBOARD_FEEDBACK = "ADD_DASHBOARD_FEEDBACK";

const SET_ACTIVE_TOURCOMPONENT = "SET_ACTIVE_TOURCOMPONENT";
const SET_TOUR_ACTIVITY = "SET_TOUR_ACTIVITY";

const REQUEST_SIDEBAR_CLOSE = "REQUEST_SIDEBAR_CLOSE";

const SHOW_VIDEO_POPUP="SHOW_VIDEO_POPUP";
const HIDE_VIDEO_POPUP="HIDE_VIDEO_POPUP";
const SHOW_AUTOMATED_FEEDBACK="SHOW_AUTOMATED_FEEDBACK";
const HIDE_AUTOMATED_FEEDBACK="HIDE_AUTOMATED_FEEDBACK"

export const showVideoPopup = () => {
  return {
    type: SHOW_VIDEO_POPUP,
    
  };
};

export const hideVideoPopup = () => {
  return {
    type: HIDE_VIDEO_POPUP,
    
  };
};

export const invokeError = (error) => {
  return {
    type: INVOKE_ERROR,
    error,
  };
};

export const requestSidebarClose = (boolVal) => {
  return {
    type: REQUEST_SIDEBAR_CLOSE,
    boolVal: boolVal,
  };
};

export const setActiveTourComponent = (tourname) => {
  return {
    type: SET_ACTIVE_TOURCOMPONENT,
    payload: tourname,
  };
};
export const setTourActivity = (tourStatus) => {
  return {
    type: SET_TOUR_ACTIVITY,
    payload: tourStatus,
  };
};
export const selectNewTabObject = (object) => {
  return {
    type: SELECT_NEWTABOBJECT,
    payload: object,
  };
};
export const setRightClickOptionsPosition = (object) => {
  return {
    type: SELECT_RIGHT_CLICK_OPTION_POSITION,
    payload: object,
  };
};
export const updateClientName = (client) => {
  return {
    type: UPDATE_CLIENTNAME,
    payload: client,
  };
};
export const deselectNewTabObject = () => {
  return {
    type: DESELECT_NEWTABOBJECT,
  };
};

export const showDashboardFeedback = () => {
  return {
    type: SHOW_DASHBOARD_FEEDBACK,
  };
};
export const hideDashboardFeedback = () => {
  return {
    type: HIDE_DASHBOARD_FEEDBACK,
  };
};
export const showTrendingForm = (formconfig) => {
  return {
    type: SHOW_TRENDINGFORM,
    payload: formconfig,
  };
};
export const hideTrendingForm = () => {
  return {
    type: HIDE_TRENDINGFORM,
  };
};

export const showTrendingDoc = () => {
  return {
    type: SHOW_TRENDINGDOC,
  };
};
export const hideTrendingDoc = () => {
  return {
    type: HIDE_TRENDINGDOC,
  };
};
export const showBannerForm = () => {
  return {
    type: SHOW_BANNER_FORM,
  };
};

export const hideBannerForm = () => {
  return {
    type: HIDE_BANNER_FORM,
  };
};
export const listAvailableToFavourite = (products) => {
  return {
    type: LIST_AVAILABLE_TO_FAVOURITE,
    products,
  };
};
export const addOrgs = (orgs) => {
  return {
    type: ADD_ORGS,
    orgs,
  };
};
export const removeSingleAvailableToFav = (id) => {
  return {
    type: REMOVE_SINGLE_AVAILABLETOFAVOURITE,
    payload: id,
  };
};
export const addSingleAvailableToFav = (dashboard) => {
  return {
    type: ADD_SINGLE_AVAILABLETOFAVOURITE,
    payload: dashboard,
  };
};
export const addSingleFavourite = (dashboard) => {
  return {
    type: ADD_SINGLE_FAVOURITE,
    payload: dashboard,
  };
};
export const addLogOrg = (org) => {
  return {
    type: ADD_LOG_ORG,
    org,
  };
};

export const setActiveDashboard = (dashboard) => {
  return {
    type: SET_ACTIVE_DASHBOARD,
    dashboard,
  };
};
export const updateFeedbackStatus = (feedback) => {
  return {
    type: UPDATE_FEEDBACK_STATUS,
    feedback,
  };
};
export const acceptError = () => {
  return {
    type: ACCEPT_ERROR,
  };
};

export const hawaiUserLoginSuccess = (session) => {
  return {
    type: HWAIUSER_LOGIN_SUCCESS,

    session,
  };
};
export const clientListRequest = () => {
  return {
    type: CLIENT_LIST_REQUEST,
  };
};

export const showSalesPopUp = (dashboardClickedArea) => {
  return {
    type: SHOW_SALESPOP,
    payload: dashboardClickedArea,
  };
};
export const hideSalesPopUp = () => {
  return {
    type: HIDE_SALESPOP,
  };
};
export const clientListFailure = (error) => {
  return {
    type: CLIENT_LIST_FAILURE,
    error,
  };
};

export const clientListSuccess = (clients) => {
  return {
    type: CLIENT_LIST_SUCCESS,
    clients,
  };
};

export const addFavouriteRequest = () => {
  return {
    type: ADD_FAVOURITE_REQUEST,
  };
};

export const showHelpDoc = () => {
  return {
    type: SHOW_HELPDOC,
  };
};
export const hideHelpDoc = () => {
  return {
    type: HIDE_HELPDOC,
  };
};
export const addFavouriteSuccess = (favourites) => {
  return {
    type: ADD_FAVOURITE_SUCCESS,
    favourites,
  };
};

export const addFavouriteFailure = (error) => {
  return {
    type: ADD_FAVOURITE_FAIURE,
    error,
  };
};

export const removeFavouriteRequest = () => {
  return {
    type: REMOVE_FAVOURITE_REQUEST,
  };
};
export const addTimerPopUp = (obj) => {
  return {
    type: ADD_TIMERPOPUP,
    payload: obj,
  };
};

export const showTour = (boolvalue) => {
  return {
    type: SHOW_TOUR,
    payload: boolvalue,
  };
};

export const hideTour = () => {
  return {
    type: HIDE_TOUR,
  };
};
export const addDashboardFeedback = (obj) => {
  return {
    type: ADD_DASHBOARD_FEEDBACK,
    payload: obj,
  };
};
export const removeFavouriteSuccess = (id) => {
  return {
    type: REMOVE_FAVOURITE_SUCCESS,
    id,
  };
};

export const removeFavouriteFailure = (error) => {
  return {
    type: REMOVE_FAVOURITE_FAIURE,
    error,
  };
};

export const refreshRequest = (payload) => {
  return {
    type: REFRESH_REQUEST,
    payload,
  };
};

export const profileUpdateRequest = (payload) => {
  return {
    type: REQUEST_PROFILE_UPDATE,
    payload,
  };
};

export const profileUpdateSuccess = (userDetails) => {
  return {
    type: SUCCESS_PROFILE_UPDATE,
    userDetails,
  };
};

export const switchMask = (mask) => {
  return {
    type: SWITCH_MASK,
    mask,
  };
};

export const addDragItem = (dashboard) => {
  return {
    type: ADD_DRAG_ITEM,
    payload: dashboard,
  };
};
export const invokeSuccess = (msg) => {
  return {
    type: INVOKE_SUCCESS,
    msg,
  };
};

export const invokeAction = () => {
  return {
    type: INVOKE_ACTION,
  };
};
export const invokeLoad = () => {
  return {
    type: INVOKE_LOAD,
  };
};

export const stopLoad = () => {
  return {
    type: STOP_LOAD,
  };
};

export const showFeedBackform = () => {
  return {
    type: SHOW_FEEDBACK_FORM,
  };
};

export const hideFeedBackform = () => {
  return {
    type: HIDE_FEEDBACK_FORM,
  };
};

export const profileUpdateFailure = (error) => {
  return {
    type: FAILURE_PROFILE_UPDATE,
    error,
  };
};

export const acceptSuccess = () => {
  return {
    type: ACCEPT_SUCCESS,
  };
};
export const newslistRequest = () => {
  return {
    type: NEWS_LIST_REQUEST,
  };
};
export const newslistSuccess = (newsinfo) => {
  return {
    type: NEWS_LIST_SUCCESS,
    newsinfo,
  };
};
export const newslistFailure = (error) => {
  return {
    type: NEWS_LIST_FAILURE,
    error,
  };
};

export const listBanner = (banner) => {
  return {
    type: BANNER_LIST,
    banner,
  };
};

export const refreshSuccess = (session, logo, clientId, orgs, userDetails) => {
  return {
    type: REFRESH_SUCCESS,
    session,
    logo,
    clientId,
    orgs,
    userDetails,
  };
};

export const refreshFailure = (error) => {
  return {
    type: REFRESH_FAILURE,
    error,
  };
};

export const listTrendings = (trendings) => {
  return {
    type: TRENDING_LIST,
    trendings,
  };
};

export const listSubscriptions = (subscriptions) => {
  return {
    type: SUBSCRIPTION_LIST,
    subscriptions,
  };
};

export const listUserDetails = (userDetails) => {
  return {
    type: USERDETAILS_LIST,
    userDetails,
  };
};

export const listNotification = (notifications) => {
  return {
    type: NOTIFICATIONS_LIST,
    notifications,
  };
};

export const listFavourites = (favourites) => {
  return {
    type: FAVOURITE_LIST,
    favourites,
  };
};

export const listWhitePapers = (whitepapers) => {
  return {
    type: WHITEPAPER_LIST,
    whitepapers,
  };
};

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

export const loginSuccess = (
  token,
  banner,
  favourites,
  notifications,
  whitepapers,
  subscriptions,
  trendings,
  userDetails,
  session,
  newsinfo,
  adds,
  logo,
  clientId
) => {
  return {
    type: LOGIN_SUCCESS,
    token,
    banner,
    favourites,
    notifications,
    whitepapers,
    subscriptions,
    trendings,
    userDetails,
    session,
    newsinfo,
    adds,
    logo,
    clientId,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    error,
  };
};

export const logoutFailure = (error) => {
  return {
    type: LOGOUT_FAILURE,
    error,
  };
};

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

export const updateSession = (session, client_id) => {
  return {
    type: UPDATE_SESSION,
    session,
    client_id,
  };
};

export const showAutomatedFeedback = () => {
  return {
    type: SHOW_AUTOMATED_FEEDBACK,
    
  };
};

export const hideAutomatedFeedback = () => {
  return {
    type: HIDE_AUTOMATED_FEEDBACK,
    
  };
};