import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import SideBar from "../components/bars/SideBar";
import NewsBar from "../components/bars/NewsBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import VideoPlayerPopup from "../components/popups/VideoPopup";
import { subsExpiredMail } from "../services/other.sevice";
import { useEffect, useRef, useState } from "react";
import SubscriptionExpiredPop from "../components/popups/SubscriptionExpiredPop";
import ThankyouPop from "../components/popups/ThankyouPop";
import FeedBackFormPop from "../components/popups/FeedBackFormPop";
import StartLoader from "../components/loaders/StartLoader";
import { getDynamicCampaignsAPI } from "../services/campaign.service";
import { getSession } from "../utils/sessionstore";
import DynamicCampaign, {
  filterCampaignsByAppearancesLeft,
  filterCampaignsByEnvironment,
  filterCampaignsByLastAppearedDate,
  filterCampaignsByPausedOrNot,
  filterCampaignsByRangeDate,
  filterCampaignsByRibbon,
  filterCampaignsByViewedOrSubmitted,
  sortCampaignsByTriggerDelay,
} from "../components/popups/DynamicCampaign";

const DefaultLayout = ({ children }) => {
  const location = useLocation();
  const token = getSession("session_hwai");

  const { clientID } = useParams();
  const { id } = useParams(); // dashboard id

  const client_id = getSession("client_id");
  const requiredClientID = clientID || client_id;
  const requiredUserID = getSession("session_hwai");

  const { isVidePop } = useSelector((state) => ({
    isVidePop: state.isVidePop,
  }));

  const subscriptionActive = useSelector((state) => state.subscriptionActive);
  const user = useSelector((state) => state.userDetails);
  const isLoading = useSelector((state) => state.isLoading);

  const [expiredpopup, setExpiredPopup] = useState(true);
  const [thankyoupoup, setThankyouPopup] = useState(false);
  const [feedbackpopup, setFeedbackPopup] = useState(false);
  const [thankyoumsg, setThankyouMsg] = useState("Thank you!");
  const [thankyousubmsg, setThankyouSubMsg] = useState(
    "We have registered you request!"
  );

  const [defaultDynamicCampaignList, setDefaultDynamicCampaignList] = useState(
    []
  );
  const [usableDynamicCampaignList, setUsableDynamicCampaignList] = useState(
    []
  );
  const [ribbonDynamicCampaignList, setRibbonDynamicCampaignList] = useState(
    []
  );
  const [activeCampaign, setActiveCampaign] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const [activeRibbonCampaign, setActiveRibbonCampaign] = useState(null);
  const [showRibbonPopup, setShowRibbonPopup] = useState(false);

  const [startedDynamicQueuesOn, setStartedDynamicQueuesOn] = useState(false);

  const [refetchDynamicCampaigns, setRefetchDynamicCampaigns] = useState(0);

  const [apiLoading, setApiLoading] = useState(false);

  function startDynamicPopupQueue() {
    console.log("queue started");
    if (usableDynamicCampaignList.length === 0) {
      console.log("queue stopped");
      setActiveCampaign(null);
      setShowPopup(false);
      return;
    }

    const dynamicCampaign = usableDynamicCampaignList[0];
    const triggerDelayInMinutes = dynamicCampaign.campaign.triggerDelay;
    const startedOn = startedDynamicQueuesOn || Date.now();

    const minutesPassed = Math.floor((Date.now() - startedOn) / 1000 / 60);

    console.log("next campaign in ", triggerDelayInMinutes, " minutes");

    setTimeout(() => {
      if (
        dynamicCampaign.campaign.environment === "DASHBOARD" &&
        dynamicCampaign.campaign.dashboard !== id
      ) {
        console.log("wrong dashboard, skipping campaign");
        usableDynamicCampaignList.splice(0, 1);
        setActiveCampaign(null);
        setShowPopup(false);
        startDynamicPopupQueue();
        return;
      }

      console.log("popup appear", dynamicCampaign);
      setActiveCampaign(dynamicCampaign);
      setShowPopup(true);
    }, (triggerDelayInMinutes - minutesPassed < 0 ? 0 : triggerDelayInMinutes - minutesPassed) * 60 * 1000);
  }

  function onRibbonClick(item) {
    console.log("ribbon popup clicked", item);

    setActiveRibbonCampaign(item);
    setShowRibbonPopup(true);
  }

  function onRibbonPopupClose() {
    console.log("ribbon popup closed");
    setShowRibbonPopup(false);
    setShowPopup(false);

    console.log("restarting queue in 10 seconds");
    setTimeout(() => {
      console.log("reset and remove ribbon campaign from queue");
      const activeRibbonCampaignIndex = ribbonDynamicCampaignList.findIndex(
        (item) => item.campaign._id === activeRibbonCampaign.campaign._id
      );
      if (activeRibbonCampaignIndex !== -1) {
        usableDynamicCampaignList.splice(activeRibbonCampaignIndex, 1);
      }
      setActiveCampaign(null);
      setActiveRibbonCampaign(null);

      console.log("restarting queue in 10 seconds");
      setTimeout(() => {
        startDynamicPopupQueue();
      }, 10 * 1000);
    }, 200);
  }

  function onDynamicPopupClose() {
    console.log("popup closed");
    setShowPopup(false);

    setTimeout(() => {
      console.log("reset and remove campaign from queue");
      usableDynamicCampaignList.splice(0, 1);
      setActiveCampaign(null);

      console.log("restarting queue in 10 seconds");
      setTimeout(() => {
        startDynamicPopupQueue();
      }, 10 * 1000);
    }, 200);
  }

  const getDynamicCampaigns = async () => {
    setApiLoading(true);
    console.log("speed of execution");
    try {
      const res = await getDynamicCampaignsAPI(token, {
        client: requiredClientID,
        user: requiredUserID,
      });
      if (res) {
        setStartedDynamicQueuesOn(new Date());

        console.log(res, "getDynamicCampaigns response");
        setDefaultDynamicCampaignList(res);
        const filteredCampaignsByPausedOrNot =
          filterCampaignsByPausedOrNot(res);
        console.log(
          filteredCampaignsByPausedOrNot,
          "filteredCampaignsByPausedOrNot"
        );
        const filteredCampaignsByEnvironment = filterCampaignsByEnvironment(
          filteredCampaignsByPausedOrNot,
          location.pathname.includes("dashboard"),
          id
        );
        console.log(
          filteredCampaignsByEnvironment,
          "filteredCampaignsByEnvironment"
        );
        const filteredCampaignsByViewedOrSubmitted =
          filterCampaignsByViewedOrSubmitted(filteredCampaignsByEnvironment);
        console.log(
          filteredCampaignsByViewedOrSubmitted,
          "filteredCampaignsByViewedOrSubmitted"
        );
        const filteredCampaignsByRangeDate = filterCampaignsByRangeDate(
          filteredCampaignsByViewedOrSubmitted
        );
        console.log(filteredCampaignsByRangeDate, "filterCampaignsByRangeDate");
        const filteredCampaignsByLastAppearedDate =
          filterCampaignsByLastAppearedDate(filteredCampaignsByRangeDate);
        console.log(
          filteredCampaignsByLastAppearedDate,
          "filterCampaignsByLastAppearedDate"
        );
        const filteredCampaignsByAppearancesLeft =
          filterCampaignsByAppearancesLeft(filteredCampaignsByLastAppearedDate);
        console.log(
          filteredCampaignsByAppearancesLeft,
          "filteredCampaignsByAppearancesLeft"
        );
        const sortedCampaignsByTriggerDelay = sortCampaignsByTriggerDelay(
          filteredCampaignsByAppearancesLeft
        );
        console.log(
          sortedCampaignsByTriggerDelay,
          "sortedCampaignsByTriggerDelay"
        );
        setUsableDynamicCampaignList(sortedCampaignsByTriggerDelay);
        const filteredCampaignsByRibbon = filterCampaignsByRibbon(
          filteredCampaignsByRangeDate
        );
        console.log(filteredCampaignsByRibbon, "filteredCampaignsByRibbon");
        setRibbonDynamicCampaignList(filteredCampaignsByRibbon);
        setApiLoading(false);
      }
    } catch (err) {
      console.log(err, "getDynamicCampaigns error");
      setApiLoading(false);
    }
  };

  useEffect(() => {
    setStartedDynamicQueuesOn(null);
    setShowPopup(false);
    setActiveCampaign(null);
    setShowRibbonPopup(false);
    setActiveRibbonCampaign(null);
    getDynamicCampaigns();
  }, [location.pathname, refetchDynamicCampaigns]);

  useEffect(() => {
    if (usableDynamicCampaignList.length > 0) {
      startDynamicPopupQueue();
    }
  }, [defaultDynamicCampaignList]);

  return (
    <>
      <DynamicCampaign
        key={1}
        onRibbonClick={onRibbonClick}
        showPopup={showPopup && !activeRibbonCampaign && !apiLoading}
        activeCampaign={activeCampaign}
        onClose={onDynamicPopupClose}
        ribbonDynamicCampaignList={[]}
        setRefetchDynamicCampaigns={() => {}}
        apiLoading={apiLoading}
      />
      <DynamicCampaign
        key={2}
        onRibbonClick={onRibbonClick}
        showPopup={showRibbonPopup && activeRibbonCampaign && !apiLoading}
        activeCampaign={activeRibbonCampaign}
        onClose={onRibbonPopupClose}
        ribbonDynamicCampaignList={ribbonDynamicCampaignList}
        queuePopupActive={!!activeCampaign}
        setRefetchDynamicCampaigns={setRefetchDynamicCampaigns}
        apiLoading={apiLoading}
      />

      {user.permissions != "HWAI_ADMIN" &&
        subscriptionActive == false &&
        expiredpopup &&
        !thankyoupoup &&
        !feedbackpopup &&
        !isLoading && (
          <SubscriptionExpiredPop
            expiredpopup={expiredpopup}
            setExpiredPopup={setExpiredPopup}
            thankyoupoup={thankyoupoup}
            setThankyouPopup={setThankyouPopup}
            feedbackpopup={feedbackpopup}
            setFeedbackPopup={setFeedbackPopup}
            thankyoumsg={thankyoumsg}
            setThankyouMsg={setThankyouMsg}
            thankyousubmsg={thankyousubmsg}
            setThankyouSubMsg={setThankyouSubMsg}
          />
        )}

      {user.permissions != "HWAI_ADMIN" &&
        subscriptionActive == false &&
        !expiredpopup &&
        !thankyoupoup &&
        feedbackpopup && (
          <FeedBackFormPop
            expiredpopup={expiredpopup}
            setExpiredPopup={setExpiredPopup}
            thankyoupoup={thankyoupoup}
            setThankyouPopup={setThankyouPopup}
            feedbackpopup={feedbackpopup}
            setFeedbackPopup={setFeedbackPopup}
            thankyoumsg={thankyoumsg}
            setThankyouMsg={setThankyouMsg}
            thankyousubmsg={thankyousubmsg}
            setThankyouSubMsg={setThankyouSubMsg}
          />
        )}

      {user.permissions != "HWAI_ADMIN" &&
        subscriptionActive == false &&
        !expiredpopup &&
        thankyoupoup &&
        !feedbackpopup && (
          <ThankyouPop
            expiredpopup={expiredpopup}
            setExpiredPopup={setExpiredPopup}
            thankyoupoup={thankyoupoup}
            setThankyouPopup={setThankyouPopup}
            thankyoumsg={thankyoumsg}
            thankyousubmsg={thankyousubmsg}
          />
        )}
      <span
        className={
          user.permissions != "HWAI_ADMIN" && subscriptionActive == false
            ? "pointer-events-none"
            : ""
        }
      >
        <Header />
        <SideBar />

        <main>{children}</main>
        {/* {console.log(isVidePop, "isVidePop hudbds")} */}
        {!location.pathname.includes("/dashboard") && <NewsBar />}
        {isVidePop && <VideoPlayerPopup />}
        <Footer />
      </span>
    </>
  );
};

export default DefaultLayout;
