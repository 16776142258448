import { memo } from "react";
const InputError = ({ error }) => {
  return (
    <div className="flex text-[#ff2d2d]" role="alert">
      <div className="Roboto text-[10px] leading-none">{error}</div>
    </div>
  );
};

export default memo(InputError);
