import { useSelector , useDispatch} from "react-redux";
import { VscFeedback } from "react-icons/vsc";
import Rating from '@mui/material/Rating';
import { useState } from "react";
import InputError from "../errors/RequiredError";
import { subsExpiredMail } from "../../services/other.sevice";

const FeedBackFormPop= ({
    expiredpopup, setExpiredPopup ,
    thankyoupoup, setThankyouPopup,
    feedbackpopup, setFeedbackPopup,
    thankyoumsg, setThankyouMsg,
    thankyousubmsg, setThankyouSubMsg
    }) => {

    const [feedbackanswers, setFeedbackAnswers] = useState({});
    const [errors, setErrors] = useState({});

    const user = useSelector((state) => state.userDetails);
    const clientname = useSelector((state) => state.clientname);
    const token = useSelector((state) => state.token);

    const handleRatingChange = (index, value) => {
        setFeedbackAnswers(prev => ({
            ...prev,
            [index]: value
        }));
    };

    const handleInputChange = (index, value) => {
        setFeedbackAnswers(prev => ({
            ...prev,
            [index]: value
        }));
    };

    const validateFeedback = () => {
        const newErrors = {};
        feedbackquestions.forEach((question, index) => {
            if (question.ansrequired) {
                if (question.type === "rating" && !feedbackanswers[index]) {
                    newErrors[index] = "Required!";
                }
                if (question.type === "descriptive" && !feedbackanswers[index]?.trim()) {
                    newErrors[index] = "Required!";
                }
            }    
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    async function sendfeedbackmail(type,feedbackdata) {
        try {
            if(type==="submit") {
                if (!validateFeedback()) {
                    return;
                }
                setExpiredPopup(false);
                setThankyouMsg("Thank you for your feedback")
                setThankyouSubMsg(<>Reach out to <a href="mailto:clientsuccess@healthworksai.com" className="text-[14px] text-[#1b75d0] underline">clientsuccess@healthworksai.com</a> for any queries on the activation status.</>)
                setThankyouPopup(true);
                setFeedbackPopup(false);
            }
            else{
                setExpiredPopup(false);
                setThankyouMsg("Thank you")
                setThankyouSubMsg(<>Reach out to <a href="mailto:clientsuccess@healthworksai.com" className="text-[14px] text-[#1b75d0] underline">clientsuccess@healthworksai.com</a> for any queries on the activation status.</>)
                setThankyouPopup(true);
                setFeedbackPopup(false);
            }
            
            const { email, firstName, lastName, jobTitle, department, userId, clientId } = user;

            const response = await subsExpiredMail(token, {
                client: clientname,
                email: email,
                firstname: firstName,
                lastname: lastName,
                JobDescription: jobTitle,
                Department: department,
                userId:userId,
                clientId:clientId,
                userfeedback: feedbackdata,
                status: type
            });

            // if (response.message == "sent successfully") {
            //   setLoader(false);
            
            // }
        } catch (e) {
            console.log("Error sendEXpMail: ", e);
        }
    }
 
    const feedbackquestions=[
        {
            question:"How easy was it for you use the platform and find relevant information?",
            type:"rating",
            ansrequired:true
        },
        {
            question:"Is there a report, data, or analysis that you would have liked to see on the portal?",
            type:"descriptive",
            ansrequired:true
        },
        {
            question:"How likely are you to recommend that your organization purchase HealthWorksAI?",
            type:"rating",
            ansrequired:true
        },
        {
            question:"What additional comments or feedback would you like to share?",
            type:"descriptive",
            ansrequired:false
        },
    ]


    return(
    <div className="absolute top-0 left-0 z-[100] select-none w-screen h-screen bg-[#0000001f] filter backdrop-blur-[3px] flex flex-col justify-center items-center gap-4">
      <span className="bg-[white] w-[800px] transition-all duration-200 ease-in-out items-center flex justify-center flex-col px-8 py-4 gap-4 ">
        <div className="h-[60px] w-[60px] rounded-full flex justify-center items-center bg-[#F7F7F7] border border-[#E9E8E8] [box-shadow:_0px_2px_8px_0px_rgba(0,0,0,0.16)]">
          <VscFeedback className="text-[32px] text-[#B8B8B8]" />
        </div>
        <div className="flex flex-col justify-center items-center gap-4">
            <div className="text-[14px] text-[#333333] leading-[20px]">
                Thank you for using the HealthWorksAI portal!  We hope that you were able to test the features and functionality that helps our clients grow faster and with greater profitability​
            </div>
            <div className="text-[14px] font-semibold text-[#7D528B]">
                We would love to get a quick feedback on the trial!
            </div>
        </div>
        <div className="w-full">
            {feedbackquestions.map((feedbackquestion,index) =>
            <div>
                {feedbackquestion.type=="rating" && 
                <div className={`flex flex-col items-start p-3 ${index%2!=0? 'bg-[#f2edf5]':'bg-[#e5dfe8]'}`}>
                    <div className="flex gap-2">
                        <div className="text-[14px] leading-3 text-[#333]">{feedbackquestion.question}</div>
                        <div className="flex self-center">{errors[index] && <InputError error={errors[index]}/>}</div>
                    </div>
                    <div className="text-[12px] italic text-[#333]">(1 being not easy at all and 5 being quite easy)​</div>
                    <Rating
                        name={`rating-${index}`}
                        sx={{gap:3, paddingTop:"5px",    
                            '& .MuiRating-iconEmpty': {
                                color: "#7D528B", 
                            }, 
                        }}
                        value={feedbackanswers[index] || 0}
                        onChange={(event, newValue) => handleRatingChange(index, newValue)}
                        onFocus={() => setErrors(prev => ({ ...prev, [index]: undefined }))}
                    />
                </div>}

                {feedbackquestion.type=="descriptive" && 
                <div className={`flex flex-col items-start p-3 ${index%2!=0? 'bg-[#f2edf5]':'bg-[#e5dfe8]'}`}>
                    <div className="flex gap-2 mb-1">
                        <div className="text-[14px] leading-none text-[#333]">{feedbackquestion.question}</div>
                        <div className="flex self-center">{errors[index] && <InputError error={errors[index]}/>}</div>
                    </div>

                    <textarea
                        className="border-2 h-[36px] py-1 leading-[16px] border-[#AC91B5] text-[#333] text-[14px] bg-transparent w-full px-2 outline-none"
                        value={feedbackanswers[index] || ''}
                        onChange={(event) => handleInputChange(index, event.target.value)}
                        onFocus={() => setErrors(prev => ({ ...prev, [index]: undefined }))}
                    />    
                </div>}
            </div>

            )}
        </div>
        <div className="flex gap-4 ">
            <button
            className="cursor-pointer text-[13px] font-semibold bg-[#7D528B] px-4 py-2 text-[white] rounded-full"
            onClick={() => {
                sendfeedbackmail("submit", feedbackquestions.map((q, index) => ({
                    question: q.question,
                    answer: feedbackanswers[index] || ''
                })) );
            }}
            >
                    Submit
            </button>
            <button
            className="cursor-pointer text-[13px] underline text-[#7D528B] rounded-full"
            onClick={() => {
                sendfeedbackmail("skip",  []);
            }}
            >
                    Skip Feedback
            </button>

        </div>
      </span>
    </div>
    )
}

export default FeedBackFormPop;