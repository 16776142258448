import { memo } from "react";
const InputError = ({ error }) => {
  return (
    <div className="flex text-[#ff2d2d] " role="alert">
      <p className="Roboto text-[10px] leading-none">Invalid! {error}</p>
    </div>
  );
};

export default memo(InputError);
