import axios from "axios";
const API_ENDPOINT = require("../config/server");
const { setSession } = require("../utils/sessionstore");

export async function subsExpiredMail(token, payload) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "SUBSCRIPTION_EXPIRED_MAIL",
      Method: "POST",
    },

    Parameters: {
      userId: token,
      ...payload,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token.trim()}`,
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  const now = new Date();
  // set the expiry time as 1hr s after the current time
  const expiresAt = new Date(+now + 2 * 60 * 60 * 1000);
  setSession("isAuth", true, expiresAt);
  setSession("session_hwai", token.trim(), expiresAt);

  return response.data;
}
