import React from "react";

const CustomContextMenu2 = ({ position, label, func }) => {
  const menuStyles = {
    position: "absolute",
    top: position.y + 2,
    left: position.x - 225,
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
  };

  const handleOptionClick = (option) => {
    // Add your logic here based on the clicked option
    // console.log("Clicked option:", option);
  };

  return (
    <div
      style={menuStyles}
      className={`absolute py-1 bg-[#dbdbdb]  w-max`}
    >
      {console.log(position, "position")}
      <div
        className="px-[10px] text-[11px] text-left w-[140px] font-['Roboto'] cursor-pointer hover:bg-[#c4c4c4] text-[#16191d]"
        onClick={func}
      >
        {label}
      </div>
      {/* Add more custom options as needed */}
    </div>
  );
};

export default CustomContextMenu2;
