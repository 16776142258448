import { MdLockOutline } from "react-icons/md";
import { useSelector , useDispatch} from "react-redux";
import { useState } from "react";
import { subsExpiredMail } from "../../services/other.sevice";

const SubscriptionExpiredPop= ({ 
      expiredpopup, setExpiredPopup ,
      thankyoupoup, setThankyouPopup,
      feedbackpopup, setFeedbackPopup,
      thankyoumsg, setThankyouMsg,
      thankyousubmsg, setThankyouSubMsg  
    }) => {


    const subscriptionHeader = useSelector((state) => state.subscriptionHeader);
    const subscriptionSubMessage = useSelector((state)=> state.subscriptionSubMessage);
    const subscriptionMessage = useSelector((state) => state.subscriptionMessage);
    const subscriptionButtonText = useSelector((state) => state.subscriptionButtonText);
    const clientname = useSelector((state) => state.clientname);
    const token = useSelector((state) => state.token);
    const user = useSelector((state) => state.userDetails);

    async function sendMail() {
        try {
          if(subscriptionButtonText.trim().toLowerCase() == 'activate') {
            setExpiredPopup(false);
            setThankyouMsg("Thank You")
            setThankyouSubMsg("We have registered your activation request. Our Client Partner team will reach out to you regarding this.")
            setThankyouPopup(true);
            setFeedbackPopup(false);
          }
          else{
            setExpiredPopup(false);
            setThankyouPopup(false);
            setFeedbackPopup(true);
          }
          
          const { email, firstName, lastName, jobTitle, department, clientId } = user;

          const response = await subsExpiredMail(token, {
            client: clientname,
            clientId: clientId,
            email: email,
            firstname: firstName,
            lastname: lastName,
            JobDescription: jobTitle,
            Department: department,
            status:subscriptionButtonText
          });
          // if (response.message == "sent successfully") {
          //   setLoader(false);
          // }
        } catch (e) {
          console.log("Error sendEXpMail: ", e);
        }
      }


    return(
    <div className="absolute top-0 left-0 z-[100] select-none w-screen h-screen bg-[#0000001f] filter backdrop-blur-[3px] flex flex-col justify-center items-center gap-4">
      <span className="bg-[white] w-[400px] transition-all duration-200 ease-in-out items-center flex justify-center flex-col px-8 py-4 gap-4 ">
        <div className="h-[60px] w-[60px] rounded-full flex justify-center items-center bg-[#F7F7F7] border border-[#E9E8E8] [box-shadow:_0px_2px_8px_0px_rgba(0,0,0,0.16)]">
          <MdLockOutline className="text-[32px] text-[#B8B8B8]" />
        </div>
        <div className="flex flex-col justify-center items-center gap-1">
            <div className="text-[16px] font-semibold text-[#333333]">
                {subscriptionHeader}
            </div>
            <div className="text-[15px] text-[#333333]">
                {subscriptionMessage}
            </div>
            <div className="text-[11px] text-[#333333]">
                {subscriptionSubMessage}
            </div>
        </div>
        <button
        className="cursor-pointer text-[13px] font-semibold bg-[#7D528B] px-4 py-2 text-[white] rounded-full"
        onClick={() => {
          sendMail();
        }}
        >
                {subscriptionButtonText}
        </button>
      </span>
    </div>
    )
}

export default SubscriptionExpiredPop;