// Reducer
import { setSession, getSession, cleanSession } from "../../utils/sessionstore";

const initialState = {
  isLoading: false,
  isAuthenticated: getSession("isAuth") || false,
  token: getSession("session_hwai"),
  hwai_user_token: getSession("session_hwai_user"),
  session_id: getSession("session_id"),
  client_id: getSession("client_id"),
  showfeedbackform: getSession("show_feedback") || false,
  dashboardClickedArea: "",
  notifications: [],
  favourites: [],
  trendings: [],
  whitepapers: [],
  subscriptions: [],
  error: "",
  failure: false,
  userDetails: getSession("userDetails") || {},
  banner: [],
  newsinfo: [],
  adds: [],
  tours: [],
  success: false,
  msg: "",
  clients: [],
  logo: "",
  isAuthenticated_hwai: false,
  feedback: true,
  orgs: getSession("orgs") || [],
  logOrg: {},
  mask: false,
  availableToFavourites: [],
  clientname: getSession("clientname") || "",
  newTabClickObject: {},
  rightClickOptionPosition: { x: 0, y: 0 },
  isHelpDoc: false,
  isTrendingDoc: getSession("isTrendingDoc") || false,
  isTrendingForm: getSession("isTrendingForm") || false,
  requestForm: getSession("requestForm") || {},
  isDashboardFeedback: false,
  draggedItem: {},
  isBannerForm: getSession("isBannerForm") || false,
  isSalesPopUp: false,
  activedashboard: {},
  timerPopup: {},
  dashboardFeedback: {},
  isTourStarter: false,
  isTourActive: false,
  activeTourComponent: {},
  clientSQLId: getSession("clientSQLId") || {},
  sidebarclose_request: false,
  isVidePop: false,
  isAutomatedFeedback: false
};

// cleanSession

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case "SHOW_VIDEO_POPUP":
      return {
        ...state,
        isVidePop: true,
      };
    case "HIDE_VIDEO_POPUP":
      return {
        ...state,
        isVidePop: false,
      };

    case "LOGIN_REQUEST":
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
      };

    case "REQUEST_SIDEBAR_CLOSE":
      return {
        ...state,
        sidebarclose_request: action.boolVal,
      };

    case "ADD_DRAG_ITEM":
      return {
        ...state,
        draggedItem: action.payload,
      };
    case "SET_ACTIVE_TOURCOMPONENT":
      return {
        ...state,
        activeTourComponent: action.payload,
      };

    case "SET_TOUR_ACTIVITY":
      return {
        ...state,
        isTourActive: action.payload,
      };
    case "ADD_DASHBOARD_FEEDBACK":
      return {
        ...state,
        dashboardFeedback: action.payload,
      };
    case "ADD_TIMERPOPUP":
      return {
        ...state,
        timerPopup: action.payload,
      };
    case "SELECT_NEWTABOBJECT":
      return {
        ...state,
        newTabClickObject: action.payload,
      };

    case "DESELECT_NEWTABOBJECT":
      return {
        ...state,
        newTabClickObject: {},
      };
    case "SELECT_RIGHT_CLICK_OPTION_POSITION":
      return {
        ...state,
        rightClickOptionPosition: action.payload,
      };
    case "SHOW_HELPDOC":
      setSession("isHelpDoc", true);
      return {
        ...state,
        isHelpDoc: true,
      };

    case "HIDE_HELPDOC":
      setSession("isHelpDoc", false);
      return {
        ...state,
        isHelpDoc: false,
      };

    case "SHOW_TOUR":
      return {
        ...state,
        isTourStarter: action.payload,
      };

    case "HIDE_TOUR":
      return {
        ...state,
        isTourStarter: false,
      };

    case "SHOW_SALESPOP":
      setSession("isSalesPopUp", true);
      return {
        ...state,
        isSalesPopUp: true,
        dashboardClickedArea: action.payload,
      };
    case "SHOW_TRENDINGDOC":
      setSession("isTrendingDoc", true);
      return {
        ...state,
        isTrendingDoc: true,
      };

    case "HIDE_TRENDINGDOC":
      setSession("isTrendingDoc", false);
      return {
        ...state,
        isTrendingDoc: false,
      };
    case "HIDE_SALESPOP":
      setSession("isSalesPopUp", false);
      return {
        ...state,
        isSalesPopUp: false,
      };
    case "SHOW_TRENDINGFORM":
      setSession("isTrendingForm", true);
      setSession("requestForm", action.payload);
      return {
        ...state,
        requestForm: action.payload,
        isTrendingForm: true,
      };

    case "HIDE_TRENDINGFORM":
      setSession("isTrendingForm", false);
      setSession("requestForm", {});
      return {
        ...state,
        isTrendingForm: false,
      };
    case "SHOW_BANNER_FORM":
      setSession("isBannerForm", true);
      return {
        ...state,
        isBannerForm: true,
      };
    case "HIDE_BANNER_FORM":
      setSession("isBannerForm", false);
      return {
        ...state,
        isBannerForm: false,
      };
    case "SHOW_DASHBOARD_FEEDBACK":
      return {
        ...state,
        isDashboardFeedback: true,
      };

    case "HIDE_DASHBOARD_FEEDBACK":
      return {
        ...state,
        isDashboardFeedback: false,
      };
    case "SWITCH_MASK":
      return {
        ...state,

        mask: action.mask,
      };

    case "SET_ACTIVE_DASHBOARD":
      
      return {
        ...state,
        activedashboard: action.dashboard,
      };
    case "ADD_LOG_ORG":
      setSession("client_id", action.org._id);
      return {
        ...state,
        logOrg: action.org,
      };
    case "UPDATE_CLIENTNAME":
      return {
        ...state,
        clientname: action.payload,
      };
    case "UPDATE_FEEDBACK_STATUS":
      return {
        ...state,
        feedback: action.feedback,
      };
    case "ADD_ORGS":
      return {
        ...state,
        orgs: action.orgs,
      };

    case "HWAIUSER_LOGIN_SUCCESS":
      setSession("isAuth_hwai", true, action.session.expires);
      setSession(
        "session_hwai_user",
        action.session.token,
        action.session.expires
      );
      return {
        ...state,
        isLoading: false,
        isAuthenticated_hwai: getSession("isAuth_hwai"),
        hwai_user_token: getSession("session_hwai_user"),
      };

    case "LOGOUT_REQUEST":
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
      };
    case "CLIENT_LIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        failure: false,
        clients: action.clients,
      };

    case "CLIENT_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
        failure: false,
      };
    case "CLIENT_LIST_FAILURE":
      return {
        ...state,
        isLoading: false,
        failure: true,
        error: action.error,
      };

    case "SHOW_FEEDBACK_FORM":
      setSession("show_feedback", true);

      return {
        ...state,
        showfeedbackform: true,
      };
    case "HIDE_FEEDBACK_FORM":
      setSession("show_feedback", false);
      return {
        ...state,
        showfeedbackform: false,
      };

    case "UPDATE_SESSION":
      setSession("isAuth", true, action.session.expires);
      setSession("session_hwai", action.session.token, action.session.expires);
      setSession("session_id", action.session._id, action.session.expires);
      setSession("client_id", action.client_id, action.session.expires);
      return {
        ...state,
      };

    case "LOGIN_SUCCESS":
      setSession("isAuth", true, action?.session?.expires);
      setSession(
        "session_hwai",
        action?.session?.token,
        action?.session?.expires
      );
      setSession("session_id", action?.session?._id, action?.session?.expires);
      setSession("client_id", action?.clientId?._id, action?.session?.expires);
      setSession("userDetails", action.userDetails, action?.session?.expires);
      setSession(
        "clientname",
        action?.clientId?.clientname,
        action?.session?.expires
      );

      setSession(
        "clientSQLId",
        action?.clientId?.clientSQLId,
        action?.session?.expires
      );
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        token: action.token,
        banner: action.banner,
        userDetails: action.userDetails,
        subscriptions: action.subscriptions,
        trendings: action.trendings,
        whitepapers: action.whitepapers,
        favourites: action.favourites,
        notifications: action.notifications,
        newsinfo: action.newsinfo,
        adds: action.adds,
        clientname: action.clientId?.clientname,
        logo: action.logo,
        subscriptionActive: action.clientId?.subscriptionActive,
        subscriptionHeader: action.clientId?.subscriptionHeader,
        subscriptionSubMessage: action.clientId?.subscriptionSubMessage,
        subscriptionMessage: action.clientId?.subscriptionMessage,
        subscriptionButtonText: action.clientId?.subscriptionButtonText
      };
    case "LOGOUT_SUCCESS":
      cleanSession("session_id");
      cleanSession("session_hwai");
      cleanSession("isAuth_hwai");
      cleanSession("client_id");
      cleanSession("isAuth");
      return {
        ...initialState,
      };

    case "LOGIN_FAILURE":
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.error,
      };
    case "LOGOUT_FAILURE":
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.error,
      };

    case "REFRESH_REQUEST":
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
      };

    case "REFRESH_SUCCESS":
      setSession("isAuth", true, action.session.expires);
      setSession("session_hwai", action.session.token, action.session.expires);
      setSession("session_id", action.session._id, action.session.expires);
      setSession("client_id", action.clientId._id, action.session.expires);
      setSession("userDetails", action.userDetails, action?.session?.expires);
      setSession(
        "clientname",
        action?.clientId?.clientname,
        action?.session?.expires
      );
      setSession("orgs", action.orgs, action?.session?.expires);
      setSession(
        "clientSQLId",
        action?.clientId?.clientSQLId,
        action?.session?.expires
      );
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        token: action.session.token,
        logo: action.logo,
        orgs: action.orgs,
        clientname: action.clientId.clientname,
        clientSQLId: action.clientId.clientSQLId,
        subscriptionActive: action.clientId?.subscriptionActive,
        subscriptionHeader: action.clientId?.subscriptionHeader,
        subscriptionSubMessage: action.clientId?.subscriptionSubMessage,
        subscriptionMessage: action.clientId?.subscriptionMessage,
        subscriptionButtonText: action.clientId?.subscriptionButtonText
      };
    case "REFRESH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.error,
      };

    case "TRENDING_LIST":
      return {
        ...state,
        trendings: action.trendings,
      };

    case "SUBSCRIPTION_LIST":
      return {
        ...state,
        subscriptions: action.subscriptions,
      };

    case "USERDETAILS_LIST":
      return {
        ...state,
        userDetails: action.userDetails,
      };

    case "NOTIFICATIONS_LIST":
      return {
        ...state,
        notifications: action.notifications,
      };

    case "WHITEPAPER_LIST":
      return {
        ...state,
        whitepapers: action.whitepapers,
      };
    case "FAVOURITE_LIST":
      return {
        ...state,
        favourites: action.favourites,
      };
    case "BANNER_LIST":
      return {
        ...state,
        banner: action.banner,
      };
    case "NEWS_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "NEWS_LIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        newsinfo: action.newsinfo,
      };
    case "NEWS_LIST_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case "REQUEST_PROFILE_UPDATE":
      return {
        ...state,
        isLoading: true,
      };

    case "SUCCESS_PROFILE_UPDATE":
      return {
        ...state,
        isLoading: false,
        userDetails: action.userDetails,
        success: true,
      };
    case "FAILURE_PROFILE_UPDATE":
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case "LIST_AVAILABLE_TO_FAVOURITE":
      return {
        ...state,
        availableToFavourites: action.products,
      };
    case "ACCEPT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case "INVOKE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        success: true,
        msg: action.msg,
      };
    case "INVOKE_ACTION":
      return {
        ...state,
        isLoading: true,
        success: false,
      };

    case "INVOKE_LOAD":
      return {
        ...state,
        isLoading: true,
        success: false,
      };
    case "STOP_LOAD":
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case "INVOKE_ERROR":
      return {
        ...state,
        isLoading: false,
        failure: true,
        error: action.error,
      };
    case "ACCEPT_ERROR":
      return {
        ...state,
        isLoading: false,
        failure: false,
      };

    case "ADD_FAVOURITE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_FAVOURITE_FAIURE":
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case "ADD_FAVOURITE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        favourites: [...action.favourites],
      };
    case "ADD_SINGLE_FAVOURITE":
      return {
        ...state,
        favourites: [...state.favourites, action.payload],
      };
    case "REMOVE_FAVOURITE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "REMOVE_FAVOURITE_FAIURE":
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case "REMOVE_FAVOURITE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        favourites: state.favourites.filter(
          (fav) => fav.dashboardId._id !== action.id
        ),
      };
    case "REMOVE_SINGLE_AVAILABLETOFAVOURITE":
      return {
        ...state,
        availableToFavourites: state.availableToFavourites.filter(
          (obj) => obj._id !== action.payload
        ),
      };
    case "ADD_SINGLE_AVAILABLETOFAVOURITE":
      return {
        ...state,
        availableToFavourites: [action.payload, ...state.availableToFavourites],
      };
    
    case "SHOW_AUTOMATED_FEEDBACK":
      return {
        ...state,
        isAutomatedFeedback: true,
      };

    case "HIDE_AUTOMATED_FEEDBACK":
      return {
        ...state,
        isAutomatedFeedback: false,
      };      
    
      
    default:
      return state;
  }
}
