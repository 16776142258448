import { memo, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { loginForAClient } from "../../services/hwai_user.service";
import {
  loginRequest,
  loginFailure,
  showTour,
  loginSuccess,
  updateFeedbackStatus,
  showFeedBackform,
  addLogOrg,
  addOrgs,
  updateClientName,
} from "../../store/auth/action";
import { getAllClients } from "../../services/hwai_user.service";
import { useSelector, useDispatch } from "react-redux";
import GeneralSearchDropDown from "./GeneralSearchDropDown";

const OrgsDropDown = () => {
  const { clientID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [defaultValue, setDefaultValue] = useState({});
  const { clientname, hwai_user_token, orgs, logOrg, userDetails } =
    useSelector((state) => ({
      clientname: state.clientname,
      hwai_user_token: state.hwai_user_token,
      orgs: state.orgs,
      logOrg: state.logOrg,
      userDetails: state.userDetails,
    }));

  async function clientLogin(token, logOrg) {
    try {
      dispatch(loginRequest());
        console.log(logOrg,"inside ord dorp down")
      const payload = { clientid: logOrg._id };
      if (!payload.clientid) {
        dispatch(loginFailure("No client was selected"));
      }
      const response = await loginForAClient(token, payload);

      const userData = response.data.user;
      const logo = userData.clientId.logo;
      const loggedClientDetails = userData.clientId;
      const subsData = response.data.subscriptions;

      const trendingsData = response.data.trendings;
      const sessionData = response.session;
      const bannerData = userData.bannerId;
      const favData = response.data.favourites;
      const notData = response.data.notification;
      const newsData = response.data.newsinfo;
      const whData = userData.whitepaperIds;
      const tempToken = sessionData.userId;
      const addsData = userData.addIds;
      const feedbackstatus = response.data.feedback;
      dispatch(showTour(userData?.showTour));
      const tempuserDetails = {
        trendingConfig: userData.trendingConfig,
        userSQLId: userData?.userSQLId,
        userName: userData?.userName,
        clientId: userData?.clientId,
        email: userData?.email,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        address: userData?.address,
        mobile: userData?.mobile,
        about: userData?.about,
        permissions: userData?.permissions,
        jobTitle: userData?.jobTitle,
        department: userData?.department,
        userId:userData?._id,
        clientId:userData?.clientId?._id
      };
      
      if (userData && subsData.length > 0) {
        dispatch(
          loginSuccess(
            tempToken,
            bannerData,
            favData,
            notData,
            whData,
            subsData,
            trendingsData,
            tempuserDetails,
            sessionData,
            newsData,
            addsData,
            logo,
            loggedClientDetails
          )
        );

        dispatch(showFeedBackform());
        dispatch(updateFeedbackStatus(feedbackstatus));
        // console.log("contra", defaultValue, logOrg);
        console.log(defaultValue,"default value ")

        if (defaultValue._id) {
          navigate(`/${defaultValue._id}`);
        } else {
          navigate(`/${clientID}`);
        }

        

      } else {
        dispatch(loginFailure("Client is not configured yet"));
      }
    } catch (err) {
      console.log(err, "error object");
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            dispatch(loginFailure("Client is not configured yet"));
            break;
          case 401:
            dispatch(
              loginFailure("Invalid Client Selection or You have no access")
            );
            break;
          default:
            dispatch(loginFailure(err.response.data));
          // code block
        }
      }
    }
  }

  useEffect(() => {
    if (!hwai_user_token) {
      navigate("/login");
    } else {
      if (logOrg._id) {
        clientLogin(hwai_user_token, logOrg);
      }
    }
  }, [defaultValue]);

  async function populateOrganizations(token) {
    const response = await getAllClients(token);
    dispatch(addOrgs(response.clients));
    // / dispatch(addLogOrg(response.clients[0]));
  }
  useEffect(() => {
    if (!hwai_user_token) {
      navigate("/login");
    } else {
      populateOrganizations(hwai_user_token);
    }
  }, [hwai_user_token]);

  // console.log("arul current : ", orgs);

  useEffect(() => {
    // console.log(logOrg, "logorg");
    let matchedOrg;
    if (clientID) {
      matchedOrg = orgs.find((obj) => obj["_id"] === clientID);
    } else {
      matchedOrg = orgs.find((obj) => obj["clientname"] === clientname);

    }
    console.log(orgs,"orgs insude drop down",clientID,matchedOrg)
  
    setDefaultValue(matchedOrg);
  }, []);

  return (
    <div className="w-[270px]">
      <GeneralSearchDropDown
        options={orgs}
        checked={defaultValue}
        setChecked={(i) => {
          setDefaultValue(i);
          dispatch(addLogOrg(i));
          dispatch(updateClientName(logOrg?.clientname));
        }}
        h={"full"}
      />
    </div>
  );
};

export default memo(OrgsDropDown);