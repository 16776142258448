import { memo } from "react";
import GeneralButton from "../buttons/GeneralButton";
import TransaparentButton from "../buttons/TransaparentButton";
import { RxCross1 } from "react-icons/rx";
import { BiTimer } from "react-icons/bi";

function LogTimerPop({ onLogout, onContinue, remainingTime, onClose }) {
  return (
    <div className="z-50 fixed w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 bottom-0 left-0 right-0">
      <div
        className="w-[430px] h-[250px] bg-[#ffffff] shadow  top-40 left-1/3 absolute "
        id="modal"
      >
        <div className="p-8">
          <div className="flex Roboto justify-between">
            <div className="font-bold text-[#333333] flex gap-1">
              <BiTimer className=" mr-1 text-3xl font-bold" />

              <div className="Roboto text-lg font-bold">Session Timeout</div>
            </div>

            <div onClick={onClose} className="cursor-pointer">
              <RxCross1 />
            </div>
          </div>

          <div className="text-left my-7">
            <p className="Roboto ">
              Your session is about to expire due to inactivity.You will be
              logged out in
            </p>

            <p>{remainingTime}</p>
          </div>

          <div className="flex justify-center gap-3">
            <TransaparentButton text={"Log Out"} onclick={onLogout} />{" "}
            <GeneralButton text={"Continue Session"} onclick={onContinue} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(LogTimerPop);
