import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSession } from "../utils/sessionstore";


import {
  accessEmbeddedDashboard,
} from "../services/session.service";


import { useNavigate, useParams } from "react-router-dom";


import { setActiveDashboard } from "../store/auth/action";

const EmbeddedLinkDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dashboard } = useParams();
  // console.log("params: ", dashboard);
  const [subname, setSubName] = useState("");

  const session_id = getSession("session_id");
  const token = getSession("session_hwai");
  const client_id = getSession("client_id");

  // console.log("sdsdsdsd", token);

  function checkSession() {
    if (!session_id) {
      navigate("/login");
    } else {
      accessEmbeddedDashboardAPI();
    }
  }



  async function accessEmbeddedDashboardAPI() {
    try {
      const response = await accessEmbeddedDashboard(
        session_id,
        token,
        client_id,
        dashboard
      );
      dispatch(setActiveDashboard(response));
      navigate("/dashboard");
      // console.log("auth response: ", response);
    } catch (e) {
      console.log("error", e);
    }
  }

  useEffect(() => {
    checkSession();
  }, [session_id]);

  return (
    <>
      <div className="overflow-y-hidden">
        <div className="absolute left-[56px] top-[56px] right-0  bottom-[60px] overflow-y-hidden ">
          <div>
            {/* Use the 'dashboard' parameter in your component */}
            {/* Dashboard: {dashboard} */}
          </div>{" "}
        </div>
      </div>
    </>
  );
};
export default memo(EmbeddedLinkDashboard);
