import axios from "axios";
import { setSession } from "../utils/sessionstore";
const API_ENDPOINT = require("../config/server");

// static campaign - log based auto feedback

export async function getCampaignForDashboard(token, payload) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_STATIC_CAMPAIGN",
      Method: "POST",
    },

    Parameters: {
      data: {
        standardDashboardId: payload,
      },
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token.trim()}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  const now = new Date();
  // set the expiry time as 1hr s after the current time
  const expiresAt = new Date(+now + 2 * 60 * 60 * 1000);
  setSession("isAuth", true, expiresAt);
  setSession("session_hwai", token.trim(), expiresAt);

  return response.data;
}

export async function saveCampaignFeedback(token, payload) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "STATIC_CAMPAIGN_FEEDBACK",
      Method: "POST",
    },

    Parameters: {
      data: payload,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token.trim()}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  const now = new Date();
  // set the expiry time as 1hr s after the current time
  const expiresAt = new Date(+now + 2 * 60 * 60 * 1000);
  setSession("isAuth", true, expiresAt);
  setSession("session_hwai", token.trim(), expiresAt);

  return response.data;
}

// dynamic campaign - admin cotrolled feedback

export async function getDynamicCampaignsAPI(token, payload) {
  // payload = {client: string id, user: string id}
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_CAMPAIGN_POPUP",
      Method: "POST",
    },

    Parameters: {
      data: payload,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token.trim()}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  const now = new Date();
  // set the expiry time as 1hr s after the current time
  const expiresAt = new Date(+now + 2 * 60 * 60 * 1000);
  setSession("isAuth", true, expiresAt);
  setSession("session_hwai", token.trim(), expiresAt);

  return response.data;
}

export async function updateDynamicCampaginUserMapAPI(token, payload) {
  // payload = { id: string id, viwedOnceOrSubmitted: boolean, apearanceCounter: number, lastAppearedOn: Date }
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "UPDATE_CAMPAIGN",
      Method: "POST",
    },

    Parameters: {
      data: payload,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token.trim()}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  const now = new Date();
  // set the expiry time as 1hr s after the current time
  const expiresAt = new Date(+now + 2 * 60 * 60 * 1000);
  setSession("isAuth", true, expiresAt);
  setSession("session_hwai", token.trim(), expiresAt);

  return response.data;
}
